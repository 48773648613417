const Error = () => {
  return (
    <article className='error'>
      <h1>404: Stuff about Kiley Not Found</h1>
      <p> Sorry! You've reached a dead end.</p>
    </article>
  );
};

export default Error;
